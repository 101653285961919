<template>
    <div>
        <el-dialog
            width="780px"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            class="bind-members-dialog"
            v-loading="loading"
        > 
            <!-- 更改绑定弹窗 -->
            <el-dialog
                width="500px"
                title="更改绑定"
                :visible.sync="innerVisible"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                class="change-bind-dialog"
                append-to-body>
                <div v-loading="innerDialogLoading">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="成员姓名" prop="name">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="成员手机号" prop="mobile">
                            <el-input v-model="ruleForm.mobile" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="所属团队" prop="team">
                            <el-input v-model="ruleForm.team" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="企业微信" prop="chooseId">
                            <el-select 
                                v-model="ruleForm.chooseId" 
                                filterable 
                                placeholder="请选择企业微信成员"
                                @change="changeSelectMember"
                                style="width:100%;">
                                <div class="option-title">
                                    <span class="name">姓名</span>
                                    <span class="department">部门</span>
                                </div>
                                <el-option
                                    v-for="item in wecomMemberDataList"
                                    :key="item.userid"
                                    :label="item.name"
                                    :value="item.userid"
                                    :disabled="item.isBind ? true : false">
                                    <div class="bind-item" v-if="item?.isBind">
                                        <span class="option-l">{{ item.name }}</span>
                                        <span class="option-c" :title="item.department">{{ item.department.join('、') }}</span>
                                        <span class="option-r" v-if="item.isBind" :title="item.bindUserName">已绑定 {{ item.bindUserName }}</span>
                                    </div>
                                    <div class="unbind-item" v-else>
                                        <span class="option-l">{{ item.name }}</span>
                                        <span class="option-c" :title="item.department">{{ item.department.join('、') }}</span>
                                        <!-- <span class="option-r same-name" v-if="item?.hasSameName">(姓名相同)</span> -->
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel" :loading="submitLoading" >取消</el-button
                    >
                    <el-button type="primary" :loading="submitLoading" @click="changeBindSubmit('ruleForm')">确认更换绑定</el-button>
                </span>
            </el-dialog>

            <div class="title" slot="title">
                <span class="text">已绑定成员</span>
                <el-button type="primary" @click="batchAddBindClick">批量添加绑定</el-button>
            </div>
            <!-- 已绑定成员列表 -->
            <div class="content">
                <el-table
                    class="bind-members-table"
                    :data="bindTableData"
                    v-if="bindTableData?.length > 0"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="userName"
                        label="成员姓名"
                        show-overflow-tooltip
                        min-width="150">
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="成员手机号"
                        width="140">
                    </el-table-column>
                    <el-table-column
                        prop="teamPath"
                        label="所属团队"
                        show-overflow-tooltip
                        min-width="120px">
                    </el-table-column>
                    <el-table-column
                        prop="weComUserName"
                        label="企业微信名"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="160"
                        :resizable="false"
                    >
                        <template slot-scope="scope">
                            <span class="change-bind-btn" @click="changeBindClick(scope.row)">更换绑定</span>
                            <span class="remove-bind-btn" @click="removeBindClick(scope.row)">解除绑定</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-data" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>
                <el-pagination
                    class="pagination-common"
                    :current-page="page.current"
                    layout="total, prev, pager, next, slot"
                    :total="page.total"
                    v-if="page.total > 10"
                    @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button
                >
            </span>
        </el-dialog>

        <!-- 批量添加绑定弹窗 -->
        <el-dialog
            width="780px"
            :visible.sync="batchAddBindDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            v-loading="loading"
            class="bind-members-dialog"
        > 
            <div class="title" slot="title">
                <span class="text">批量添加绑定</span>
            </div>
            <!-- 未绑定成员列表 -->
            <div class="content" v-loading="batchLoading">
                <el-table
                    class="bind-members-table"
                    :data="unBindTableData"
                    v-if="unBindTableData?.length > 0"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="userName"
                        label="成员姓名"
                        show-overflow-tooltip
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="成员手机号"
                        width="150">
                    </el-table-column>
                    <el-table-column
                        prop="teamPath"
                        min-width="120px"
                        show-overflow-tooltip
                        label="所属团队">
                    </el-table-column>
                    <el-table-column
                        prop="weComUserId"
                        label="企业微信名"
                        width="220">
                        <template slot-scope="{row}">
                            <el-select 
                                v-model="row.weComUserId"
                                filterable 
                                clearable
                                placeholder="请选择企业微信成员"
                                @change="(val) => changeBatchSelectMember(val, row)"
                                style="width:100%;">
                                <div class="option-title">
                                    <span class="name">姓名</span>
                                    <span class="department">部门</span>
                                </div>
                                <el-option
                                    v-for="item in wecomMemberDataList"
                                    :key="item.userid"
                                    :label="item.name"
                                    :value="item.userid"
                                    :disabled="item.isBind ? true : false">
                                    <div class="bind-item" v-if="item?.isBind">
                                        <span class="option-l">{{ item.name }}</span>
                                        <span class="option-c" :title="item.department">{{ item.department.join('、') }}</span>
                                        <span class="option-r" v-if="item.isBind" :title="item.bindUserName">已绑定 {{ item.bindUserName }}</span>
                                    </div>
                                    <div class="unbind-item" v-else>
                                        <span class="option-l">{{ item.name }}</span>
                                        <span class="option-c" :title="item.department">{{ item.department.join('、') }}</span>
                                        <!-- <span class="option-r same-name" v-if="item?.hasSameName">(姓名相同)</span> -->
                                    </div>
                                </el-option>
                            </el-select>
                            <div 
                                class="select-tip" 
                                v-show="showSelectTip && selectRepeatId == row.weComUserId" 
                                v-text="selectTipText">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-data" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>
                <el-pagination
                    class="pagination-common"
                    :current-page="batchPage.current"
                    layout="total, prev, pager, next, slot"
                    :total="batchPage.total"
                    v-if="batchPage.total > 10"
                    @current-change="handleBatchCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="batchPagerJump" @keyup.enter.native="handleBatchPagerJump"></el-input>页</span>
                        <span @click="handleBatchPagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <span class="bind-count">共 <i>{{ bindCount }}</i> 条绑定信息</span>
                <el-button @click="batchAddBindDialogVisible = false">取消</el-button
                >
                <el-button type="primary" :loading="submitLoading" @click="batchAddBindSubmit">添加绑定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import EnterpriseSetService from '#/js/service/enterpriseSetService.js';

export default {
    data() {
        return {
            dialogVisible: false,
            innerVisible: false,
            batchAddBindDialogVisible: false,
            loading: false,
            innerDialogLoading: false,
            batchLoading: false,
            submitLoading: false,
            page: {
                current: 1,
                size: 10,
                total: 0
            },
            pagerJump: 0,
            batchPage: {
                current: 1,
                size: 10,
                total: 0
            },
            batchPagerJump: 0,

            bindTableData: [],
            allBindTableData: [],
            unBindTableData: [],
            allUnBindTableData: [],

            ruleForm: {
                id: '',
                name: '',
                mobile: '',
                team: '',
                chooseId: '',
                chooseName: '',
            },
            rules: {
                chooseId: [
                    { required: true, message: '请选择企业微信', trigger: 'change' }
                ],
            },

            wecomMemberDataList: [],

            bindCount: 0,
            selectUsers: [],
            selectRepeatId: '', // 选择重复的企业微信用户id
            showSelectTip: false,
            selectTipText: '该企微用户已被选择，请重新选择',
            batchSelectHasRepeat: false,
        };
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        show() {
            this.page.current = 1;

            this.dialogVisible = true;
            this.getBindMembersList();

            this.getAllWecomMemberList();
        },
        // 已绑定成员列表分页
        handleCurrentChange(val) {
            this.page.current = val;
            // this.getBindMembersList();
            // 前端分页
            this.bindTableData = this.allBindTableData.slice((this.page.current - 1) * this.page.size, this.page.current * this.page.size);
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },
        // 获取绑定成员列表
        getBindMembersList() {
            this.loading = true;
            let params = {
                isBind: true,
                // offset: (this.page.current - 1) * this.page.size,
                // pageSize: this.page.size
            }
            EnterpriseSetService.getWecomUserBindList(params)
                .then((res) => {
                    // console.log(res);
                    this.allBindTableData = res.data || [];

                    // this.page.total = res.data.length;
                    this.page.total = res.count;
                    // 前端分页
                    this.bindTableData = this.allBindTableData.slice((this.page.current - 1) * this.page.size, this.page.current * this.page.size);
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
        },

        // 更换绑定取消
        cancel() {
            this.innerVisible = false;
            // 重置
            this.resetForm('ruleForm');
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        changeBindClick(row) {
            // console.log(row);
            this.innerVisible = true;

            this.ruleForm.id = row.userId;
            this.ruleForm.name = row.userName;
            this.ruleForm.mobile = row.mobile;
            this.ruleForm.team = row.teamPath;
            this.ruleForm.chooseId = row.weComUserId;

            // this.getAllWecomMemberList();
        },
        // 确认更换绑定提交
        changeBindSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    let params = {
                        userId: this.ruleForm.id,
                        weComUserName: this.ruleForm.chooseName,
                        weComOpenUserId: this.ruleForm.chooseId
                    }
                    EnterpriseSetService
                        .getChangeUserBind(params)
                        .then(async res => {
                            // console.log(res);
                            shortTips("更改绑定成功");

                            this.cancel();
                            await this.getAllWecomMemberList();
                            await this.getBindMembersList();
                        }).catch(e => {
                            console.log(e);
                        }).finally(() => {
                            this.submitLoading = false;
                        });

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        removeBindClick(row) {
            // console.log(row);
            this.$confirm(`确定要解除成员 <span class="text-primary">${row.userName}</span> 的企业微信绑定吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning'
            }).then(() => {
                this.removeBindSubmit(row);
            }).catch((e) => {
                console.log(e);          
            });
        },
        // 确认解除绑定提交
        removeBindSubmit(row) {
            // console.log(row);
            let params = {
                userId: row.userId,
                // weComUserName: row.weComUserName,
                weComOpenUserId: row.weComUserId
            }
            EnterpriseSetService
                .handleUserUnbind(params)
                .then(async res => {
                    // console.log(res);
                    shortTips("解绑成功");

                    await this.getAllWecomMemberList();
                    await this.getBindMembersList();
                }).catch(e => {
                    console.log(e);
                })
        },

        // 获取企业微信所有成员列表
        getAllWecomMemberList() {
            this.innerDialogLoading = true;
            this.batchLoading = true;
            EnterpriseSetService
                .getAllWecomMemberInfo()
                .then(res => {
                    // console.log(res);
                    this.wecomMemberDataList = res;

                    // // 判断下拉用户列表是不是有相同姓名的数据
                    // var mapCount = {}
                    // this.wecomMemberDataList.forEach(item => {
                    //     item.hasSameName = false;
                    //     if (mapCount[item.name]) {
                    //         mapCount[item.name]++;
                    //     } else {
                    //         mapCount[item.name] = 1;
                    //     }
                    // });
                    // Object.keys(mapCount).forEach(key => {
                    //     if (mapCount[key] > 1) {
                    //         this.wecomMemberDataList.forEach(item => {
                    //             if (item.name === key) {
                    //                 item.hasSameName = true;
                    //             }
                    //         });
                    //     }
                    // });
                    // // console.log(this.wecomMemberDataList);
                }).catch(e => {
                    console.log(e);
                }).finally(() => {
                    this.innerDialogLoading = false;
                    this.batchLoading = false;
                });
        },

        async batchAddBindClick() {
            this.batchPage.current = 1;
            // localStorage.removeItem('batchSelectUsers');

            this.batchAddBindDialogVisible = true;
            // this.getAllWecomMemberList();
            await this.getUnBindMembersList();
        },
        setIdNull(arr) {
            // console.log(arr);
            var mapCount = {}
            arr.forEach(item => {
                if (mapCount[item.weComUserId]) {
                    mapCount[item.weComUserId]++;
                } else {
                    mapCount[item.weComUserId] = 1;
                }
            });

            Object.keys(mapCount).forEach(key => {
                if (mapCount[key] > 1) {
                    arr.forEach(item => {
                        if (item.weComUserId === key) {
                            item.weComUserId = null;
                        }
                    });
                }
            });
        },
        // 未绑定成员列表分页
        handleBatchCurrentChange(val) {
            this.batchPage.current = val;
            // this.getUnBindMembersList();
            // 前端分页
            this.unBindTableData = this.allUnBindTableData.slice((this.batchPage.current - 1) * this.batchPage.size, this.batchPage.current * this.batchPage.size);
        },
        handleBatchPagerJump(){
            let currentPager = Number(this.batchPagerJump),
            currentPageTotal = Math.ceil(this.batchPage.total/this.batchPage.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleBatchCurrentChange(currentPager)
            }
        },
        // 获取未绑定成员列表
        getUnBindMembersList() {
            this.batchLoading = true;
            let params = {
                isBind: false,
                // offset: (this.batchPage.current - 1) * this.batchPage.size,
                // pageSize: this.batchPage.size
            }
            EnterpriseSetService.getWecomUserBindList(params)
                .then((res) => {
                    // console.log(res);
                    this.allUnBindTableData = res.data || [];

                    // this.batchPage.total = res.data.length;
                    this.batchPage.total = res.count;
                    // 前端分页
                    this.unBindTableData = this.allUnBindTableData.slice((this.batchPage.current - 1) * this.batchPage.size, this.batchPage.current * this.batchPage.size);

                    /* 
                        打开后，系统先自动对"猎必得账号的成员姓名"和"企业微信姓名"进行匹配。 
                        默认选择姓名相同的企微；
                        没有姓名相匹配的数据默认不选择;
                        有重名的默认不选择 
                    */
                    // 绑定了的就不再匹配,过滤已绑定的数据
                    let wecomMemberDataListFilter = this.wecomMemberDataList.filter(el => {
                        return !el.isBind;
                    })

                    let arrNames = []; // 存姓名
                    let arrSameNames = []; // 存重名的姓名
                    this.wecomMemberDataList.forEach(el => {
                        if(arrNames.indexOf(el.name) == -1) {
                            arrNames.push(el.name);
                        } else {
                            arrSameNames.push(el.name);
                        }
                    })
                    // console.log(arrNames);
                    // console.log(arrSameNames);

                    this.allUnBindTableData.forEach(item1 => {
                        wecomMemberDataListFilter.forEach(item2 => {
                            // 企业微信下拉选项成员中有重名的不自动匹配
                            if(arrSameNames.includes(item2.name)) {
                                return;
                            } else {
                                if (item1.userName.split('@')[0] == item2.name) {
                                    item1.weComUserId = item2.userid;
                                }
                            }
                        });
                    });
                    // 猎必得成员中有重名的不自动匹配
                    this.setIdNull(this.allUnBindTableData);
                    // console.log(this.unBindTableData);

                    this.selectUsers = this.allUnBindTableData.filter(el => {
                        return el.weComUserId;
                    })
                    this.selectUsers.forEach(el => {
                        el.weComUserName = el.userName.split('@')[0];
                    })
                    this.bindCount = this.selectUsers?.length || 0;
                    console.log(this.selectUsers);

	                // localStorage.setItem(`batchSelectUsers`, JSON.stringify(this.selectUsers));
                    // console.log(JSON.parse(localStorage.getItem('batchSelectUsers')));
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.batchLoading = false;
                });
        },
        
        // 更改绑定change事件
        changeSelectMember(val) {
            // let resultArr = this.wecomMemberDataList.filter((item)=>{
            //     return item.userid === val;
            // });
            // this.ruleForm.chooseName = resultArr[0].name;
            const resultObj =  this.wecomMemberDataList.find(item => item.userid === val);
            this.ruleForm.chooseName = resultObj.name;
        },
        // 批量添加绑定change事件
        // 方法1：
        changeBatchSelectMember(val,row) {
            // console.log('企微选择id----');
            // console.log(val);
            // console.log('列表用户----');
            // console.log(row);
            if(!row.weComUserId) {
                row.weComUserId = null;
                row.weComUserName = null;
            }

            const resultObj =  this.wecomMemberDataList.find(item => item.userid === val);
            // console.log(resultObj);
            if(resultObj) {
                row.weComUserName = resultObj.name;
            }

            this.selectUsers = this.allUnBindTableData.filter(el => {
                return el.weComUserId;
            })
            this.bindCount = this.selectUsers?.length || 0;
            console.log(this.selectUsers);

            let arrIds = []; //存id
            for(let i in this.selectUsers){
                if(arrIds.indexOf(this.selectUsers[i].weComUserId) == -1){
                    arrIds.push(this.selectUsers[i].weComUserId);

                    this.selectRepeatId = '';
                    this.showSelectTip = false;
                    this.batchSelectHasRepeat = false;
                } else {
                    this.selectRepeatId = this.selectUsers[i].weComUserId;
                    this.showSelectTip = true;
                    this.batchSelectHasRepeat = true;
                    return;
                }
            }
            // console.log(this.batchSelectHasRepeat);
        },
        // // 批量添加绑定change事件
        // // 方法2：
        // changeBatchSelectMember(val,row) {
        //     // console.log('企微选择id----');
        //     // console.log(val);
        //     // console.log('列表用户----');
        //     // console.log(row);
        //     if(!row.weComUserId) {
        //         row.weComUserId = null;
        //         row.weComUserName = null;
        //     }

        //     const resultObj =  this.wecomMemberDataList.find(item => item.userid === val);
        //     // console.log(resultObj);
        //     if(resultObj) {
        //         row.weComUserName = resultObj.name;
        //     }
        //     console.log(row);

        //     let tempSelectUsers = JSON.parse(localStorage.getItem('batchSelectUsers'));

        //     // 如果row.weComUserId为null,则说明是清除了该项选择
        //     if(row.weComUserId) {
        //         // 如果是同一项选择，先把之前push进去的对象删除掉，再重新push新选择的进去
        //         let isSameRow = tempSelectUsers.some(el => {
        //             return el.userId == row.userId;
        //         })
        //         if(isSameRow) {
        //             tempSelectUsers = tempSelectUsers.filter(el => {
        //                 return el.userId != row.userId;
        //             })
        //         }
        //         tempSelectUsers.push(row);
        //     } else {
        //         tempSelectUsers = tempSelectUsers.filter(el => {
        //             return el.userId != row.userId;
        //         })
        //     }

        //     this.bindCount = tempSelectUsers?.length || 0;
        //     localStorage.setItem(`batchSelectUsers`, JSON.stringify(tempSelectUsers));

        //     let arrIds = []; //存id
        //     for(let i in tempSelectUsers){
        //         if(arrIds.indexOf(tempSelectUsers[i].weComUserId) == -1){
        //             arrIds.push(tempSelectUsers[i].weComUserId);

        //             this.selectRepeatId = '';
        //             this.showSelectTip = false;
        //             this.batchSelectHasRepeat = false;
        //         } else {
        //             this.selectRepeatId = tempSelectUsers[i].weComUserId;
        //             this.showSelectTip = true;
        //             this.batchSelectHasRepeat = true;
        //             return;
        //         }
        //     }
        //     console.log(this.batchSelectHasRepeat);
        // },
        // 批量添加绑定提交
        batchAddBindSubmit() {
            console.log('批量提交-------');
            
            console.log(this.selectUsers);
            if(this.selectUsers.length == 0) {
                shortTips("未选择要绑定的用户");
                return;
            }
            // let tempSelectUsers = JSON.parse(localStorage.getItem('batchSelectUsers'));
            // console.log(tempSelectUsers);
            // if(tempSelectUsers.length == 0) {
            //     shortTips("未选择要绑定的用户");
            //     return;
            // }

            if(this.batchSelectHasRepeat) {
                shortTips("企业微信用户重复选择，请选择其它用户");
                return;
            }

            let users = this.selectUsers.map(item => {
                return {
                    userId: item.userId,
                    weComUserName: item.weComUserName,
                    weComOpenUserId: item.weComUserId
                }
            })
            // let users = tempSelectUsers.map(item => {
            //     return {
            //         userId: item.userId,
            //         weComUserName: item.weComUserName,
            //         weComOpenUserId: item.weComUserId
            //     }
            // })
            console.log(users);
            this.submitLoading = true;
            EnterpriseSetService.batchUserBind({users: users})
                .then(async res => {
                    // console.log(res);
                    shortTips("批量绑定成功");

                    // localStorage.removeItem('batchSelectUsers');

                    await this.getAllWecomMemberList();
                    await this.getUnBindMembersList();
                    await this.getBindMembersList();

                    if(unBindTableData.length > 0) {
                        this.batchAddBindDialogVisible = true;
                    } else {
                        this.batchAddBindDialogVisible = false;
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.submitLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.bind-members-dialog {
    .el-dialog__header {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
            }
            .el-button {
                height: 28px;
                line-height: 28px;
            }
        }
    }
    .el-dialog__body {
        padding: 0;
        max-height: 500px;
        overflow-y: scroll;
        .content {
            .select-tip {
                color: #FF6564;
                font-size: 12px;
            }
            .empty-data {
                text-align: center;
            }
            .bind-members-table {
                margin-bottom: 20px;
                 &::before {
                    height: 0;
                }
                /deep/.el-table__header {
                    th {
                        height: 40px;
                        line-height: 40px;
                        padding: 0;
                        border-bottom: none;
                    }
                }
                /deep/.el-table__body {
                    td {
                        height: 40px;
                        line-height: 40px;
                        padding: 0;
                        border-bottom: none;
                    }
                    .el-select {
                        .el-input__inner {
                            height: 32px;
                        }
                        .el-input__suffix {
                            .el-input__icon {
                                line-height: 32px;
                            }
                        }
                    }
                }
                .change-bind-btn {
                    margin-right: 10px;
                    cursor: pointer;
                    color: #38BC9D;
                }
                .remove-bind-btn {
                    cursor: pointer;
                    color: #FF6564;
                }
            }
        }
    }
    .dialog-footer {
        .bind-count {
            float: left;
            padding: 0 10px;
            line-height: 32px;
            i {
                color: #38BC9D;
            }
        }
    }
}

.change-bind-dialog {
    /deep/.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 20px 20px 0;
        }
    }
}

.el-select-dropdown  {
    .el-tree.my-tree {
        .member {
            color: #38BC9D;
            .member-bind {
                color: #999;
                cursor: not-allowed;
            }
        }
    }

    .option-title {
        padding: 4px 20px;
        color: #666;
        font-size: 14px;
        font-weight: bold;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;
        border-bottom: 1px solid #eee;
        .name {
            display: inline-block;
            width: 60px;
            margin-right: 20px;
        }
        .department {
            display: inline-block;
            width: 140px;
        }
    }
    .el-select-dropdown__item {
        .bind-item {
            display: flex;
            align-items: center;
            color: #999;
            cursor: not-allowed;
        }
        .unbind-item {
            display: flex;
            align-items: center;
            .option-c {
                color: #606266;
            }
        }
        .option-l {
            display: inline-block;
            width: 60px;
            margin-right: 20px;
        }
        .option-c {
            display: inline-block;
            // min-width: 100px;
            // max-width: 150px;
            width: 140px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .option-r {
            margin-left: 10px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .same-name {
            color: #38BC9D;
        }
    }
}

.text-primary {
    color: #38BC9D;
}
</style>
