import {
    enterpriseSet as enterpriseSetUrl,
} from '#/js/config/javaApi.json';

export default {
    // 获取企业微信用户绑定列表
    getWecomUserBindList(params) {
        return _request({
            method: 'POST',
            url: enterpriseSetUrl.get_wecom_user_bind_list,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    //更换用户绑定
    getChangeUserBind(params) {
        return _request({
            method: 'POST',
            url: enterpriseSetUrl.change_user_bind,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 解除绑定
    handleUserUnbind(params) {
        return _request({
            method: 'POST',
            url: enterpriseSetUrl.user_unbind,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    //批量添加用户绑定
    batchUserBind(params) {
        return _request({
            method: 'POST',
            url: enterpriseSetUrl.batch_user_bind,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 获取企业微信授权绑定
    getWecomDepartmentTree(params) {
        return _request({
            method: 'POST',
            url: enterpriseSetUrl.get_wecom_department_tree,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 获取企业微信部门成员信息
    getWecomMemberInfo(params) {
        return _request({
            method: 'GET',
            url: enterpriseSetUrl.get_wecom_member_info,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 获取企业微信所有成员信息
    getAllWecomMemberInfo(params) {
        return _request({
            method: 'GET',
            url: enterpriseSetUrl.get_all_member_info,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },
}