<template>
    <div class="enterprise-wechat-set">
        <div class="enterprise-wechat-set-wrap" v-loading="loading">
            <div class="top-wrap">
                <h1>自建应用代开发授权</h1>
                <div class="tip-content">
                    <p>
                        通过代开发自建应用，您进行企业微信授权登录猎必得，将企业微信的成员和猎必得成员进行账号绑定，实现在企业微信进行猎必得消息沟通，审核流程推送等，赶快试试吧！
                    </p>
                    <p>
                        请到企业微信后台设置代开发自建应用的可见范围和应用负责人。
                    </p>
                </div>
                <div class="option">
                    <div class="un-authorized" v-if="authorizeStatus == 0">
                        <el-button type="primary" @click="handleAuthorize">开始授权</el-button>
                        <span>代开发应用为试用模式，试用时长不限制。</span>
                    </div>
                    <div class="authorized-option" v-else>
                        <span v-if="authorizeStatus == 13">
                            <i class="status">应用已上线</i>
                            <span class="firmName">企业微信企业名称：{{ weChatFirmName }}</span>
                            <el-button @click="cancelAuthorize">取消授权</el-button>
                        </span>
                        <div v-else>
                            <span ><i class="status">已授权</i>将在<i>1</i>个工作日内为您配置好应用，请 <em @click="addCustomerService">扫码联系在线客服</em> 完成后续配置</span>
                            <span class="cancel-authorize" @click="cancelAuthorize">取消授权</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-wrap" v-if="true">
                <div class="set-item" v-if="authorizeStatus == 13">
                    <h5 class="set-title">授权企业微信扫码登录猎必得</h5>
                    <div class="text"> 
                        通过企业微信用户授权，企业微信用户可以和猎必得账号进行绑定，使用企业微信扫码可登录猎必得账号。
                    </div>
                    <el-radio-group v-model="loginStatus" :disabled="authorizeStatus != 13" v-if="false">
                        <el-radio-button :label="true">开启</el-radio-button>
                        <el-radio-button :label="false">关闭</el-radio-button>
                    </el-radio-group>
                    <span class="view-bind-member" @click="viewBindMembers">查看绑定成员</span>
                </div>
                <template v-if="false">
                    <div class="set-item">
                        <h5 class="set-title">组织架构审核通知</h5>
                        <div class="text">
                            猎必得【组织架构】的审核消息，将会推送至审核人企业微信中提醒处理。
                        </div>
                        <el-radio-group v-model="departmentStatus" :disabled="authorizeStatus != 13">
                            <el-radio-button :label="true">开启</el-radio-button>
                            <el-radio-button :label="false">关闭</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="set-item">
                        <h5 class="set-title">大厂机会审批通知</h5>
                        <div class="text">
                            猎必得【大厂机会】的审核消息，将会推送至审核人企业微信中提醒处理。
                        </div>
                        <el-radio-group v-model="auditStatus" :disabled="authorizeStatus != 13">
                            <el-radio-button :label="true">开启</el-radio-button>
                            <el-radio-button :label="false">关闭</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="set-item">
                        <h5 class="set-title">大厂机会投递通知</h5>
                        <div class="text">
                            猎必得【大厂机会】在获得候选人选投递时，将会推送至可跟进简历用户的企业微信中提醒跟进。
                        </div>
                        <el-radio-group v-model="deliveryStatus" :disabled="authorizeStatus != 13">
                            <el-radio-button :label="true">开启</el-radio-button>
                            <el-radio-button :label="false">关闭</el-radio-button>
                        </el-radio-group>
                    </div>
                </template>
            </div>
        </div>

        <!-- 添加客服助手弹窗 -->
        <el-dialog
            title="联系客服"
            :visible.sync="dialogVisible"
            width="400px"
            class="add-customer-service-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <div class="content">
                <p>【扫码添加】客服小助手完成后续配置</p>
                <div class="qr">
                    <img src="~@src/assets/images/customer-service-qr.jpg" alt="">
                </div>
            </div>
        </el-dialog>
        <!-- 取消授权弹窗 -->
        <el-dialog
            title="取消授权"
            :visible.sync="cancelAuthorizeDialogVisible"
            width="800px"
            class="cancel-authorize-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleCloseCancelAuthorize">
            <div class="content">
                <p>在【企业微信移动端】-【工作台】-【管理企业】-【应用管理】，选择应用并删除。</p>
                <div class="option-img">
                    <img src="~@src/assets/images/unbind-operation.png" alt="">
                </div>
            </div>
        </el-dialog>
        <!-- 授权弹窗 -->
        <el-dialog
            title="自建应用代开发授权"
            :visible.sync="authorizeDialogVisible"
            width="600px"
            class="authorize-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleCloseAuthorize">
            <div class="content">
                <div v-if="!isNext">
                    <p>进入“企业微信后台->我的企业->企业信息”，可在最底部获取到“企业ID”，用于绑定企业微信的企业。</p>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
                        <el-form-item label="企业微信企业ID" prop="corpId">
                            <el-input v-model.trim="ruleForm.corpId"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <p>授权服务商代开发【猎必得】应用</p>
                    <div id="qrcode" class="qr-code" ref="qrcode"></div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div v-if="!isNext">
                    <el-button @click="handleCloseAuthorize">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')"  :loading="nextLoading">下一步</el-button>
                </div>
                <div v-else>
                    <el-button type="primary" @click="finishAuthorize">已完成扫码授权</el-button>
                </div>
            </span>
        </el-dialog>

        <bind-members-dialog ref="bindMembersDialogRef"></bind-members-dialog>
    </div>
</template>

<script>
import CertificationService from '#/js/service/certificationService.js';
import QRCode from 'qrcodejs2';
import BindMembersDialog from './components/bind-members-dialog.vue';

export default {
    components: {
        QRCode,
        BindMembersDialog
    },

    data() {
        return {
            loading: false,
            authorizeStatus: 0,
            weChatFirmName: '',

            nextLoading: false,
            dialogVisible: false,
            cancelAuthorizeDialogVisible: false,
            authorizeDialogVisible: false,
            ruleForm: {
                corpId: '',
            },
            rules: {
                corpId: [
                    { required: true, message: '企业微信企业ID', trigger: 'blur' },
                ],
            },

            isNext: false,

            loginStatus: false,
            departmentStatus: false,
            auditStatus: false,
            deliveryStatus: false,
        };
    },

    computed: {
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
    },

    mounted() {
        if(this.$store.state.user.userInfo.isAdministrator || this.isHuntingCompanySetPermission){
            this.getFirmThirdBoundInfo();
        } else {
            shortTips("权限不足，请联系管理员");
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },

    methods: {
        getFirmThirdBoundInfo() {
            this.loading = true;
            CertificationService
                .FirmThirdBoundInfo()
                .then(res => {
                    // console.log(res);
                    // [Display(Name = "未授权")]
                    // Unauthorized = 0,

                    // [Display(Name = "待开发")]
                    // ToBeDeveloped = 3,

                    // [Display(Name = "开发中")]
                    // Developing = 5,

                    // [Display(Name = "审核中")]
                    // YunBi = 7,

                    // [Display(Name = "待上线")]
                    // WaitingForLaunch = 9,

                    // [Display(Name = "已上线")]
                    // Online = 13,

                    // [Display(Name = "已解除绑定")]
                    // Unbound = 17,
                    this.authorizeStatus = res.status;
                    this.weChatFirmName = res.firmName;
                })
                .catch(e => {
                    console.log(e);
                }).finally(() => {
                    this.loading = false;
                });
        },
        
        qrCode(data) {
            // console.log(data);
            this.$nextTick(() => {
                // let qrcodeEl = document.getElementById('qrcode');
                let qrcodeEl = this.$refs.qrcode;
                // console.log(qrcodeEl);
                // console.log(QRCode);
                let qrcode = new QRCode(qrcodeEl, {
                    // text: data.qrcode_url,
                    text: data,
                    width: 250,
                    height: 250,
                    colorDark : "#000000",
                    colorLight : "#ffffff",
                    correctLevel : QRCode.CorrectLevel.H
                });
                console.log(qrcode);
            });
        },

        resetForm(formName) {
            this.$nextTick(()=> {
                this.$refs[formName].resetFields();
            })
        },
        handleAuthorize() {
            this.authorizeDialogVisible = true;
            this.resetForm('ruleForm');
        },
        cancelAuthorize() {
            this.cancelAuthorizeDialogVisible = true;
        },
        addCustomerService() {
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleCloseCancelAuthorize() {
            this.cancelAuthorizeDialogVisible = false;
        },
        handleCloseAuthorize() {
            this.authorizeDialogVisible = false;
            this.isNext = false;

            // 已完成授权之后刷新授权状态
            this.getFirmThirdBoundInfo();
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.nextLoading = true;
                    CertificationService.WeComFirmRegister(this.ruleForm.corpId)
                    .then(res =>{
                        // console.log(res);
                        this.isNext = true;

                        this.$nextTick(()=> {
                            // console.log(QRCode);
                            this.qrCode(res);
                        })
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        this.nextLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                }
            });
        },
        finishAuthorize() {
            this.authorizeDialogVisible = false;
            this.isNext = false;

            // 已完成授权之后刷新授权状态
            this.getFirmThirdBoundInfo();

            this.addCustomerService();
        },

        viewBindMembers() {
            this.$refs.bindMembersDialogRef.show();
        }
    },
};
</script>

<style lang="scss" scoped>
.enterprise-wechat-set {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    .enterprise-wechat-set-wrap {
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 30px;
        .top-wrap {
            h1 {
                font-size: 16px;
                color: #333;
                font-weight: 600;
            }
            .tip-content {
                color: #999;
                p {
                    margin-bottom: 5px;
                }
            }
            .option {
                margin-top: 20px;
                .status {
                    color: #38BC9D;
                    margin-right: 15px;
                }
                .un-authorized {
                    span {
                        margin-left: 5px;
                        font-size: #999;
                        font-size: 12px;
                    }
                }
                .authorized-option {
                    .el-button {
                        color: #38BC9D;
                        border-color: #38BC9D;
                    }
                    span {
                        color: #999;
                        em {
                            cursor: pointer;
                            color: #38BC9D;
                        }
                    }
                    .firmName {
                        color: #F5A623;
                        margin-right: 15px;
                    }
                    .cancel-authorize {
                        cursor: pointer;
                        color: #F5A623;
                        margin-left: 36px;
                    }
                }
            }
        }
        .bottom-wrap {
            padding-top: 10px;
            .set-item {
                .set-title {
                    margin: 40px 0 20px;
                    font-size: 20px;
                    color: #333333;
                    &::before {
                        content: "";
                        display: inline-block;
                        height: 20px;
                        width: 4px;
                        background-color: #38bc9d;
                        margin-right: 12px;
                        vertical-align: top;
                    }
                }
                .text {
                    margin-bottom: 10px;
                }
                .view-bind-member {
                    cursor: pointer;
                    color: #38BC9D;
                }
            }
        }
    }

    /deep/.el-dialog {
        .el-dialog__body {
            padding: 20px;
        }
    }
    .add-customer-service-dialog {
        .content {
            text-align: center;
            .qr {
                width: 250px;
                height: 250px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
        }
    }
    .cancel-authorize-dialog {
        .content {
            .option-img {
                img {
                    width: 100%;
                }
            }
        }
    }
    .authorize-dialog {
        .content {
            .el-form {
                margin-top: 20px;
            }
            .qr-code{
                width: 250px;
                height: 250px;
                margin: 20px auto 0;
            }
        }
    }
}
</style>