var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "enterprise-wechat-set" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "enterprise-wechat-set-wrap",
        },
        [
          _c("div", { staticClass: "top-wrap" }, [
            _c("h1", [_vm._v("自建应用代开发授权")]),
            _vm._m(0),
            _c("div", { staticClass: "option" }, [
              _vm.authorizeStatus == 0
                ? _c(
                    "div",
                    { staticClass: "un-authorized" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAuthorize },
                        },
                        [_vm._v("开始授权")]
                      ),
                      _c("span", [
                        _vm._v("代开发应用为试用模式，试用时长不限制。"),
                      ]),
                    ],
                    1
                  )
                : _c("div", { staticClass: "authorized-option" }, [
                    _vm.authorizeStatus == 13
                      ? _c(
                          "span",
                          [
                            _c("i", { staticClass: "status" }, [
                              _vm._v("应用已上线"),
                            ]),
                            _c("span", { staticClass: "firmName" }, [
                              _vm._v(
                                "企业微信企业名称：" +
                                  _vm._s(_vm.weChatFirmName)
                              ),
                            ]),
                            _c(
                              "el-button",
                              { on: { click: _vm.cancelAuthorize } },
                              [_vm._v("取消授权")]
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", [
                            _c("i", { staticClass: "status" }, [
                              _vm._v("已授权"),
                            ]),
                            _vm._v("将在"),
                            _c("i", [_vm._v("1")]),
                            _vm._v("个工作日内为您配置好应用，请 "),
                            _c(
                              "em",
                              { on: { click: _vm.addCustomerService } },
                              [_vm._v("扫码联系在线客服")]
                            ),
                            _vm._v(" 完成后续配置"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "cancel-authorize",
                              on: { click: _vm.cancelAuthorize },
                            },
                            [_vm._v("取消授权")]
                          ),
                        ]),
                  ]),
            ]),
          ]),
          true
            ? _c(
                "div",
                { staticClass: "bottom-wrap" },
                [
                  _vm.authorizeStatus == 13
                    ? _c(
                        "div",
                        { staticClass: "set-item" },
                        [
                          _c("h5", { staticClass: "set-title" }, [
                            _vm._v("授权企业微信扫码登录猎必得"),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              " \n                    通过企业微信用户授权，企业微信用户可以和猎必得账号进行绑定，使用企业微信扫码可登录猎必得账号。\n                "
                            ),
                          ]),
                          false
                            ? _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.authorizeStatus != 13,
                                  },
                                  model: {
                                    value: _vm.loginStatus,
                                    callback: function ($$v) {
                                      _vm.loginStatus = $$v
                                    },
                                    expression: "loginStatus",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: true } },
                                    [_vm._v("开启")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: false } },
                                    [_vm._v("关闭")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "view-bind-member",
                              on: { click: _vm.viewBindMembers },
                            },
                            [_vm._v("查看绑定成员")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? [
                        _c(
                          "div",
                          { staticClass: "set-item" },
                          [
                            _c("h5", { staticClass: "set-title" }, [
                              _vm._v("组织架构审核通知"),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n                        猎必得【组织架构】的审核消息，将会推送至审核人企业微信中提醒处理。\n                    "
                              ),
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.authorizeStatus != 13 },
                                model: {
                                  value: _vm.departmentStatus,
                                  callback: function ($$v) {
                                    _vm.departmentStatus = $$v
                                  },
                                  expression: "departmentStatus",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: true } },
                                  [_vm._v("开启")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: false } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "set-item" },
                          [
                            _c("h5", { staticClass: "set-title" }, [
                              _vm._v("大厂机会审批通知"),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n                        猎必得【大厂机会】的审核消息，将会推送至审核人企业微信中提醒处理。\n                    "
                              ),
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.authorizeStatus != 13 },
                                model: {
                                  value: _vm.auditStatus,
                                  callback: function ($$v) {
                                    _vm.auditStatus = $$v
                                  },
                                  expression: "auditStatus",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: true } },
                                  [_vm._v("开启")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: false } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "set-item" },
                          [
                            _c("h5", { staticClass: "set-title" }, [
                              _vm._v("大厂机会投递通知"),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n                        猎必得【大厂机会】在获得候选人选投递时，将会推送至可跟进简历用户的企业微信中提醒跟进。\n                    "
                              ),
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.authorizeStatus != 13 },
                                model: {
                                  value: _vm.deliveryStatus,
                                  callback: function ($$v) {
                                    _vm.deliveryStatus = $$v
                                  },
                                  expression: "deliveryStatus",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: true } },
                                  [_vm._v("开启")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: false } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-customer-service-dialog",
          attrs: {
            title: "联系客服",
            visible: _vm.dialogVisible,
            width: "400px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("p", [_vm._v("【扫码添加】客服小助手完成后续配置")]),
            _c("div", { staticClass: "qr" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/customer-service-qr.jpg"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "cancel-authorize-dialog",
          attrs: {
            title: "取消授权",
            visible: _vm.cancelAuthorizeDialogVisible,
            width: "800px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleCloseCancelAuthorize,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cancelAuthorizeDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                "在【企业微信移动端】-【工作台】-【管理企业】-【应用管理】，选择应用并删除。"
              ),
            ]),
            _c("div", { staticClass: "option-img" }, [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/unbind-operation.png"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "authorize-dialog",
          attrs: {
            title: "自建应用代开发授权",
            visible: _vm.authorizeDialogVisible,
            width: "600px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleCloseAuthorize,
          },
          on: {
            "update:visible": function ($event) {
              _vm.authorizeDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            !_vm.isNext
              ? _c(
                  "div",
                  [
                    _c("p", [
                      _vm._v(
                        "进入“企业微信后台->我的企业->企业信息”，可在最底部获取到“企业ID”，用于绑定企业微信的企业。"
                      ),
                    ]),
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          model: _vm.ruleForm,
                          rules: _vm.rules,
                          "label-width": "130px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "企业微信企业ID", prop: "corpId" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.corpId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "corpId",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.corpId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", [
                  _c("p", [_vm._v("授权服务商代开发【猎必得】应用")]),
                  _c("div", {
                    ref: "qrcode",
                    staticClass: "qr-code",
                    attrs: { id: "qrcode" },
                  }),
                ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.isNext
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.handleCloseAuthorize } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.nextLoading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("下一步")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.finishAuthorize },
                        },
                        [_vm._v("已完成扫码授权")]
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ]
      ),
      _c("bind-members-dialog", { ref: "bindMembersDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip-content" }, [
      _c("p", [
        _vm._v(
          "\n                    通过代开发自建应用，您进行企业微信授权登录猎必得，将企业微信的成员和猎必得成员进行账号绑定，实现在企业微信进行猎必得消息沟通，审核流程推送等，赶快试试吧！\n                "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n                    请到企业微信后台设置代开发自建应用的可见范围和应用负责人。\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }